import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from '../components/tooltip';
import { certifications } from '../routes/_index';

export const HavenAwayFromHomeSection = () => {
  const { t } = useTranslation(['home', 'common']);

  return (
    <section id="haven" className="flex flex-col w-full px-8 sm:px-12 md:px-24 lg:px-64">
      <h2 className="text-2xl md:text-3xl font-bold font-display text-center">
        {t('home:haven.title')}
      </h2>
      <p className="px-8 mb-4 mt-4 sm:mb-0 max-w-[640px] m-auto md:text-xl text-center">
        <Trans i18nKey="home:haven.premium-village">
          A premium eco-village from{' '}
          <a
            className="text-blue-500 underline"
            href="https://sukritwellness.com"
            target="_blank"
            rel="noreferrer"
          >
            Sukrit Wellness Tours
          </a>
          , a registered tour operator with <strong>decades of experience</strong> with
          Kumbh. Located in the Kumbh corridor, it stands in an{' '}
          <strong>exclusive zone</strong> which is <strong>calm and serene</strong>.
        </Trans>
      </p>
      <p className="mt-16 border-t border-dashed border-t-black/20 text-center text-sm text-gray-400 pt-6">
        {t('home:sw-certified')}
      </p>
      <ul className="mt-3 flex flex-wrap md:flex md:flex-row list-none gap-8 items-center justify-center border-b border-dashed border-b-black/20 pb-6">
        {certifications.map(({ title, src, alt, className }, index) => (
          <li key={index} className="flex flex-col">
            <Tooltip text={title}>
              <img
                src={src}
                alt={alt}
                className={`h-20 object-contain ${className} select-none pointer-events-none`}
              />
            </Tooltip>
          </li>
        ))}
      </ul>
    </section>
  );
};
