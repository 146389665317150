import { Trans } from 'react-i18next';

export const KumbhSukritHeader = () => {
  return (
    <section className="w-full relative h-[700px] xs:h-[840px] sm:h-[880px] md:h-[900px] lg:h-[900px] xl:h-[1000px]">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        controls={false}
        src="https://pub-3acbfdb36a6a4a758770a8294a62150d.r2.dev/ks-montage-v1-compressed.mp4"
        className="object-cover w-full  md: h-full -z-10"
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
      />
      <div className="bg-black/20 t w-full h-full absolute left-0 bottom-0" />
      <hgroup className="absolute top-[60%] -translate-y-[60%] bottom-[unset] md:translate-y-0 md:top-[unset] md:bottom-48 text-center md:text-left w-full px-8 sm:px-12 md:px-24 lg:px-64">
        <h1 className="text-white font-semibold text-3xl md:text-3xl lg:text-4xl font-display">
          <Trans i18nKey="home:header.celebrate">
            Celebrate the Kumbh Mela at{' '}
            <span className="font-bold text-green-200">Sanskriti Vann</span>
          </Trans>
        </h1>
        <p className="text-white text-lg mt-4">
          <Trans i18nKey="home:header.join">
            Join the biggest congregation of humanity in the most safe,
            <br className="hidden sm:block" />
            serene and and sanitised eco-village of Kumbh 2025.
          </Trans>
        </p>
      </hgroup>
      <div className="h-32 w-full absolute left-0 bottom-0 bg-gradient-to-b from-white/0 to-green-100" />
    </section>
  );
};
