import { useMemo } from 'react';
import { SignificanceCard } from '../components/significance-card';
import { images } from '../constants/images';
import { Trans, useTranslation } from 'react-i18next';

export type Significance = 'spiritual' | 'cultural' | 'philosophical';

export const KumbhSignificanceSection = () => {
  const { t } = useTranslation(['home', 'common']);

  const significanceOfKumbh = useMemo(
    () => ({
      spiritual: {
        title: t('home:significance.spiritual.title'),
        content: (
          <Trans i18nKey="home:significance.spiritual.content">
            The word &ldquo;Kumbh&rdquo; means pot, symbolizing the human body filled with
            the nectar of immortality. This nectar represents the soul and supreme
            consciousness within us. Kumbh signifies the journey towards spiritual
            fulfillment, where realizing this inner divinity leads to bliss and a deep
            connection with the divine. It embodies the spiritual ascent and ultimate
            contentment through self-awareness and spiritual practices.
          </Trans>
        ),
      },
      cultural: {
        title: t('home:significance.cultural.title'),
        content: (
          <Trans i18nKey="home:significance.cultural.content">
            Kumbh has immense cultural importance, showcasing the depth of Indian
            traditions and heritage. Millions gather to participate in rituals that
            highlight the rich cultural tapestry of India. This grand event attracts
            global attention, reflecting the unity and shared values of humanity, making
            Kumbh a significant cultural phenomenon.
          </Trans>
        ),
      },
      philosophical: {
        title: t('home:significance.philosophical.title'),
        content: (
          <Trans i18nKey="home:significance.philosophical.content">
            Kumbh represents the eternal quest for knowledge and understanding of
            life&lsquo;s true essence. The concept of &lsquo;Amrit&rsquo; (nectar)
            symbolizes the immortal truth. Through meditation and self-awareness, seekers
            move towards this eternal element, experiencing profound peace and
            enlightenment. Kumbh guides individuals towards self-realization and the
            ultimate truth, serving as a beacon of spiritual wisdom.
          </Trans>
        ),
      },
    }),
    [t],
  );

  return (
    <section
      id="kumbh"
      className="mt-16 md:mt-28 relative flex flex-col w-full px-8 sm:px-12 md:px-24 bg-orange-50 lg:px-64 z-0 py-28 transition duration-300"
    >
      <img
        className="absolute left-0 top-0 w-full h-full opacity-20 object-cover z-[-1] transition-all"
        src={images['amrit']}
        alt="The forest ambience of Sanskriti Vann"
      />
      <h2 className="text-2xl md:text-3xl font-bold font-display text-center m-auto">
        {t('home:significance.pot.title')}
      </h2>
      <p className="mt-4 sm:mb-0 max-w-[640px] text-center md:text-xl m-auto font-sans">
        <Trans i18nKey="home:significance.pot.lore">
          Kumbh literally translates to &lsquo;pot&rsquo;. The tradition of Kumbh tells
          the story of the <i>Devās</i> and <i>Asurās</i> (gods and demons), who fought
          over a pot which contained Amrit (the nectar of immorality).
        </Trans>
      </p>
      <ul className="flex flex-col gap-4 mt-12">
        {Object.keys(significanceOfKumbh).map((significance, key) => (
          <SignificanceCard
            {...significanceOfKumbh[significance as Significance]}
            key={key}
          />
        ))}
      </ul>
      <div className="absolute bottom-0 w-full left-0 h-36 bg-gradient-to-b from-white/0 to-green-100" />
    </section>
  );
};
