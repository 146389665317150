import React from 'react';
import { Accordion } from './accordion';

export interface SignificanceProps {
  title: string;
  content: React.ReactNode;
}

export const SignificanceCard: React.FC<SignificanceProps> = ({ content, title }) => {
  return (
    <li className="w-full bg-white rounded-md flex flex-col items-center">
      <Accordion
        className="w-full font-serif text-xl py-6"
        title={`${title}`}
        content={content}
      />
    </li>
  );
};
