import React, { useCallback, useMemo, useState } from 'react';
import { images } from '../constants/images';
import Sprite from '../sprites/sprite';
import { Testimonial } from '../components/testimonial/testimonial';
import { useTranslation } from 'react-i18next';

interface Kumbh2019SectionProps {
  onClick: (src: string) => void;
}
export const Kumbh2019Section: React.FC<Kumbh2019SectionProps> = ({ onClick }) => {
  const [playing, setPlaying] = useState(false);
  const onClickPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const { t } = useTranslation(['home', 'common']);

  const testimonials = useMemo(
    () => [
      {
        imageSrc: images['avatar-richard'],
        imageAlt: t('home:testimonials.richard.alt'),
        quote: t('home:testimonials.richard.quote'),
        author: {
          name: t('home:testimonials.richard.name'),
          location: t('home:testimonials.richard.location'),
          videoSrc: 'https://www.youtube.com/embed/wqfyTic9L7E?si=qcS-27EwZ2HZqSCi',
        },
      },
      {
        imageSrc: images['avatar-rajesh'],
        imageAlt: t('home:testimonials.rajesh.alt'),
        quote: t('home:testimonials.rajesh.quote'),
        author: {
          name: t('home:testimonials.rajesh.name'),
          location: t('home:testimonials.rajesh.location'),
          videoSrc: 'https://www.youtube.com/embed/5tSohpCNSJ8?si=3GhJ68RwnNTQ3wdl',
        },
      },
      {
        imageSrc: images['avatar-gabriella'],
        imageAlt: t('home:testimonials.gabriella.alt'),
        quote: t('home:testimonials.gabriella.quote'),
        author: {
          name: t('home:testimonials.gabriella.name'),
          location: t('home:testimonials.gabriella.location'),
          videoSrc: 'https://www.youtube.com/embed/LygBGodZrk4?si=XDkG7YWTnNl3UNjM',
        },
      },
      {
        imageSrc: images['avatar-pawars'],
        imageAlt: t('home:testimonials.pawars.alt'),
        quote: t('home:testimonials.pawars.quote'),
        author: {
          name: t('home:testimonials.pawars.name'),
          location: t('home:testimonials.pawars.location'),
          videoSrc: 'https://www.youtube.com/embed/SeRjwcdlVFQ?si=31UUByi98xRs-0H8',
        },
      },
    ],
    [t],
  );

  return (
    <section
      id="2019"
      className="mt-16 md:mt-28 flex flex-col w-full px-8 sm:px-12 md:px-24"
    >
      <h2 className="text-2xl md:text-3xl font-bold font-display text-center">
        {t('home:2019.edition')}
      </h2>
      <p className="px-8 mt-1 sm:mb-0 max-w-[640px] m-auto text-center font-serif">
        {t('home:2019.guests')}
      </p>
      <div className="w-[1008px] max-w-[95%] m-auto mt-8">
        {playing ? (
          <iframe
            className="w-full md:min-w-[1008px] h-[480px]"
            src="https://www.youtube.com/embed/XUIs_7__LKY?si=Z2JzV5kMyAfPeIhl&autoplay=1&loop=1&autopause=0&muted=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        ) : (
          <div className="w-full h-[480px] relative">
            <img
              className="w-full h-full object-cover"
              src={images['testimonials-thumbnail']}
              alt="English testimonials thumbnail of Claudia, from Italy"
            />
            <Sprite
              name="play"
              width="64"
              height="64"
              className="cursor-pointer absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              onClick={onClickPlay}
            />
            <div className="absolute w-full px-5 left-1/2 text-white text-center -translate-x-1/2 bottom-32 md:bottom-28">
              <p className="font-serif font-bold text-2xl md:text-3xl">
                {t('home:2019.moments')}
              </p>
              <p className="text-sm md:text-base">{t('home:2019.visitors')}</p>
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 items-center w-fit m-auto gap-x-8 gap-y-10 justify-center mt-12">
        {testimonials.map((testimonial, index) => (
          <Testimonial
            {...testimonial}
            onClick={() => onClick(testimonial.author.videoSrc)}
            key={index}
          />
        ))}
      </div>
    </section>
  );
};
