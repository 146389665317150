import clsx from 'clsx';
import { images } from '../constants/images';
import React, { useEffect, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

interface PackageCardProps {
  id: PackageId;
  src: string;
  categories: string[];
  packageTitle: string;
  duration: number;
  price: string;
  size: 'small' | 'large';
}

enum PackageCategory {
  HISTORY = 'history',
  SPIRITUALITY = 'spirituality',
  KUMBHMELA = 'kumbh mela',
}

export enum PackageId {
  MAHAKUMBH = 'mahakumbh',
  VARANASI = 'varanasi',
  AYODHYA = 'ayodhya',
}

export const usePackageCards = (t: TFunction<['home', 'common'], undefined>) => {
  return useMemo(
    () =>
      [
        {
          src: images['mahakumbh-package'],
          categories: [PackageCategory.HISTORY, PackageCategory.KUMBHMELA],
          packageTitle: t('home:packages.kumbh.card.title'),
          duration: 5,
          price: 'Prayagraj',
          size: 'large',
          id: PackageId.MAHAKUMBH,
        },
        {
          src: images['mandir'],
          categories: [PackageCategory.SPIRITUALITY],
          packageTitle: t('home:packages.varanasi.card.title'),
          duration: 8,
          price: 'Prayagraj, Varanasi',
          size: 'small',
          id: PackageId.VARANASI,
        },
        {
          src: images['pilgrimage-to-ayodhya'],
          categories: [PackageCategory.HISTORY, PackageCategory.SPIRITUALITY],
          packageTitle: t('home:packages.ayodhya.card.title'),
          duration: 9,
          price: 'Prayagraj, Varanasi, Delhi',
          size: 'small',
          id: PackageId.AYODHYA,
        },
      ] satisfies PackageCardProps[],
    [t],
  );
};

export const getUrlForPackageId = (id: PackageId) => {
  const origin = window.location.origin;
  return `${origin}/#package-${id}`;
};

export const PackageCard: React.FC<
  PackageCardProps & { onClick: (packageTitle: string, packageId: PackageId) => void }
> = ({ src, categories, packageTitle, duration, price, size, id, onClick }) => {
  const { t } = useTranslation(['home', 'common']);

  useEffect(() => {
    const split = window.location.href.split('/');
    if (split[split.length - 1] === `#package-${id}`) {
      onClick(packageTitle, id);
    }
  }, [id, onClick, packageTitle]);

  return (
    <div
      id={`package-${id}`}
      className={clsx(
        'z-0 relative w-full h-96 rounded-md flex flex-col justify-between p-8 cursor-auto',
        {
          'w-full': size === 'large',
          'w-full md:w-[calc(50%_-_12px)]': size === 'small',
        },
      )}
    >
      <img
        loading="lazy"
        src={src}
        alt=""
        className="w-full h-full absolute left-0 top-0 object-top -z-10 object-cover rounded-md"
      />
      <div className="h-60 bottom-0 left-0 absolute w-full bg-gradient-to-b from-black/0 to-black/60 -z-10 rounded-b-md" />
      <div className="flex flex-row items-center justify-center md:justify-normal gap-1">
        {categories.map((category, index) => (
          <span
            className="uppercase px-2 py-0.5 bg-orange-100 rounded-md text-sm tracking-widest text-gray-900"
            key={index}
          >
            {category}
          </span>
        ))}
      </div>
      <div className="flex flex-col md:flex-row items-center w-full justify-between">
        <hgroup className="text-white flex flex-col text-center md:text-left">
          <p className="font-display text-2xl">{packageTitle}</p>
          <p>
            {duration} days • {price}
          </p>
        </hgroup>
        <button
          onClick={() => onClick(packageTitle, id)}
          className="mt-4 text-green-900 uppercase font-bold text-xs tracking-widest bg-white rounded-sm px-4 py-2"
        >
          {t('common:see-more')}
        </button>
      </div>
    </div>
  );
};
