import { images } from '../constants/images';
import Sprite from '../sprites/sprite';
import { useAudioVisualiser } from '../hooks/use-audio-visualiser';
import { useTranslation } from 'react-i18next';

const firstColumn = [
  images['esteemed-5'],
  images['haven-2'],
  images['sanskriti-van-hero-bg'],
  images['gallery-7'],
  images['serenity-and-peace-4'],
];

const secondColumn = [
  images['gallery-5'],
  images['serenity-and-peace'],
  images['esteemed-2'],
  images['safe-and-secure'],
];

const thirdColumn = [
  images['haven-6'],
  images['gallery-6'],
  images['gallery-8'],
  images['sattvic-and-fresh'],
];

const columns = [firstColumn, secondColumn, thirdColumn];

export const SanskritiVanSection = () => {
  const { isPlaying, handleAudioPlay, handleAudioEnd, canvasRef } =
    useAudioVisualiser('/sanskriti-theme.mp3');

  const { t } = useTranslation(['home', 'common']);

  return (
    <>
      <section id="sanskriti" className="w-full relative py-32 mt-20 z-0 bg-green-200">
        <canvas
          className="absolute top-0 left-0 w-full h-36 -z-10 rotate-180"
          ref={canvasRef}
        />
        <img
          className="absolute left-0 top-0 w-full h-full opacity-20 object-cover z-[-1]"
          src={images['sanskriti-van-hero-bg']}
          alt="The forest ambience of Sanskriti Vann"
        />
        <hgroup className="m-auto text-center md:m-[unset] px-2 md:text-left sm:px-12 md:px-24 lg:px-64">
          <div className="flex flex-row items-center gap-3 justify-center md:justify-normal">
            <h2 className="text-2xl md:text-3xl font-bold font-display flex flex-row justify-center md:justify-normal items-center">
              {t('common:sanskriti-van.title')}
            </h2>
            <div className="flex flex-col items-center justify-center relative w-11 h-11">
              <div className="animate-pulse rounded-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10 w-[44px] h-[44px] bg-green-300 duration-75" />
              {isPlaying ? (
                <Sprite
                  name="mute"
                  width="17"
                  height="12"
                  onClick={handleAudioEnd}
                  className="w-10 h-10 p-3 bg-green-100 cursor-pointer hover:bg-green-50 rounded-full"
                />
              ) : (
                <Sprite
                  name="unmute"
                  width="16"
                  height="12"
                  onClick={handleAudioPlay}
                  className="w-10 h-10 p-3 bg-green-100 cursor-pointer hover:bg-green-50 rounded-full"
                />
              )}
            </div>
          </div>
          <p className="text-lg font-serif mt-1">{t('home:sanskriti-van.unique')}</p>
          <p className="mt-4 md:mt-8 md:text-xl max-w-[500px] px-2 md:px-0 m-auto md:m-[unset] leading-normal">
            {t('home:sanskriti-van.location')}
          </p>
        </hgroup>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 m-auto mt-12 w-full px-8 sm:px-12 md:px-24">
          {columns.map((column, index) => (
            <ul key={index} className="flex flex-col gap-3">
              {column.map((src, key) => (
                <li key={key}>
                  <img
                    src={src}
                    className="object-cover w-full max-h-96 md:max-h-[unset] rounded-md select-none pointer-events-none"
                    alt=""
                  />
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="absolute bottom-0 w-full left-0 h-36 bg-gradient-to-b from-white/0 to-green-100" />
      </section>
    </>
  );
};
