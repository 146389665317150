import React from 'react';
import { Modal } from './modal';

interface VideoModalProps {
  videoSrc: string | null;
  isOpen: boolean;
  onClose: VoidFunction;
}
export const VideoModal: React.FC<VideoModalProps> = ({ videoSrc, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <iframe
        className="max-w-[95%] w-[1008px] h-[480px]"
        src={`${videoSrc}&autoplay=1&loop=1&autopause=0&muted=1` ?? ''}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </Modal>
  );
};
