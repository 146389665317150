import React from 'react';

import Sprite from '../../sprites/sprite';
import { Tooltip } from '../tooltip';

export interface TestimonialItem {
  imageSrc: string;
  imageAlt: string;
  quote: string;
  author: {
    name: string;
    location: string;
    videoSrc: string;
  };
}

export const Testimonial: React.FC<TestimonialItem & { onClick: VoidFunction }> = ({
  author,
  imageAlt,
  imageSrc,
  quote,
  onClick,
}) => {
  return (
    <div className="flex flex-row items-start gap-3 w-fit">
      <div className="relative">
        <img
          loading="lazy"
          src={imageSrc}
          alt={imageAlt}
          className="w-16 h-16 min-w-16 min-h-16 rounded-full select-none pointer-events-none object-cover"
        />
        <Tooltip
          text="View Testimonial"
          className="absolute right-0 bottom-0 font-mono text-xs"
          spacing="compact"
        >
          <Sprite
            name="play"
            width="64"
            height="64"
            className="w-6 h-6 cursor-pointer"
            onClick={onClick}
          />
        </Tooltip>
      </div>
      <div className="flex flex-col w-fit">
        <p className="max-w-[420px] text-sm ">{quote}</p>
        <p className="uppercase mt-1 tracking-wider text-xs text-orange-400 font-medium">
          {author.name}, {author.location}
        </p>
      </div>
    </div>
  );
};
