import { useCallback, useState } from 'react';

import type { ActionFunctionArgs, MetaFunction } from '@remix-run/cloudflare';
import { Navigation } from '../components/navigation';
import { images } from '../constants/images';
import { json } from '@remix-run/react';
import { retry } from '../retry';
import { ApplicationEnvironment } from '../environment';
import { KumbhSignificanceSection } from '../sections/kumbh-significance';
import { AccommodationAndPackagesSection } from '../sections/accommodation-and-packages';
import { Kumbh2019Section } from '../sections/kumbh-2019';
import { SanskritiVanSection } from '../sections/sanskriti-van';
import { HavenAwayFromHomeSection } from '../sections/haven-away-from-home';
import { KumbhSukritHeader } from '../components/kumbh-sukrit-header';
import { VideoModal } from '../components/video-modal';
import { Footer } from '../components/footer';

export const meta: MetaFunction = () => {
  return [
    { title: 'Kumbh Sukrit 2025' },
    {
      name: 'description',
      content: 'Celebrate the Kumbh Mela at Sanskriti Vann',
    },
  ];
};

export const certifications = [
  {
    src: images['ministry-ayush'],
    alt: 'Associated with Ministry of Ayush',
    title: 'Ministry of Ayush',
    className: 'max-h-16',
  },
  {
    src: images['ycb'],
    alt: 'Certified by the Yoga Certification Board of Ministry of Ayush',
    title: 'Yoga Certification Board, Ministry of Ayush',
    className: 'max-h-16',
  },
  {
    src: images['mot-medal'],
    alt: 'Certified as an Inbound Tour Operator by the Ministry of Tourism India',
    title: 'Inbound Tour Operator, India',
    className: 'max-h-16',
  },
  {
    src: images['incin-logo'],
    alt: 'Certified under Incredible India',
    title: 'Incredible India',
    className: 'max-h-10',
  },
  {
    src: images['icc'],
    title: 'Indian Chamber of Commerce',
    alt: 'Certified by the Indian Chamber of Commerce',
    className: 'max-h-16',
  },
  {
    src: images['iato'],
    alt: 'Certified by the Indian Association of Tour Operators',
    title: 'Indian Association of Tour Operators, India',
    className: 'max-h-16',
  },
  {
    src: images['mot-govin'],
    alt: 'Certified by the Ministry of Tourism India',
    title: 'Ministry of Tourism, Government of India',
    className: 'max-h-16',
  },
];

export const action = async (args: ActionFunctionArgs) => {
  const request = args.request;
  const environment = args.context.cloudflare.env as ApplicationEnvironment;

  const apiUrl = environment.API_URL;
  const formData = await request.formData();

  const email = formData.get('email')?.toString() ?? '';
  const name = formData.get('name')?.toString() ?? '';
  const phone = formData.get('phone')?.toString() ?? '';
  const inquiry = formData.get('inquiry')?.toString() ?? '';

  try {
    const response = await retry('post-inquiry', (signal) =>
      fetch(`${apiUrl}/api/inquiry`, {
        body: JSON.stringify({ email, name, phone, inquiry }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        signal,
      }),
    );

    if (!response.ok) {
      throw Error('Error: inquiry failed');
    }

    return json({ success: true });
  } catch (e) {
    console.error(e);
  }

  return json({ success: false });
};

export default function Index() {
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onClick = useCallback((src: string) => {
    setIsOpen(true);
    setVideoSrc(src);
  }, []);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className="w-full bg-green-100">
      <Navigation />
      <KumbhSukritHeader />
      <main className="w-full mt-16 md:mt-28">
        <VideoModal videoSrc={videoSrc} isOpen={isOpen} onClose={onClose} />
        <HavenAwayFromHomeSection />
        <SanskritiVanSection />
        <Kumbh2019Section onClick={onClick} />
        <AccommodationAndPackagesSection />
        <KumbhSignificanceSection />
      </main>
      <Footer />
    </div>
  );
}
