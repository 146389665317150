import Sprite from '../sprites/sprite';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';

interface AccordionProps {
  title: string;
  content: React.ReactNode;
  className?: string;
}

export const Accordion: React.FC<AccordionProps> = ({ title, content, className }) => {
  const answerRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <details
      open={isOpen}
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (!(element.contains(answerRef.current) || element.tagName === 'A')) {
          e.preventDefault();
          setIsOpen((prev) => !prev);
        }
      }}
      className={`py-4 px-6 font-bold cursor-pointer select-none border-2 border-[#A1F6A5] rounded-md ${className} transition`}
    >
      <summary className="flex flex-row items-center justify-between font-semibold">
        <span>{title}</span>
        <Sprite
          name="down"
          width="56"
          height="30"
          className={clsx(
            'w-4 h-2 min-w-4 min-h-2 md:min-w-5 md:min-h-3 md:w-5 md:h-3 transition',
            {
              'rotate-180': isOpen,
            },
          )}
          stroke="black"
          strokeWidth={6}
        />
      </summary>
      <div ref={answerRef} className="mt-5 font-normal font-sans not-italic text-base">
        {content}
      </div>
    </details>
  );
};
