import { snackbarState } from '../components/snackbar/snackbar-state';

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    snackbarState.setMessage({
      message: 'common:snackbar.clipboard',
      type: 'success',
      icon: '📋',
    });

    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      snackbarState.setMessage({
        message: 'common:snackbar.clipboard',
        type: 'success',
        icon: '📋',
      });
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    },
  );
}
